const dev = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "ap-northeast-1",
    BUCKET: "notes-app-user-data"
  },
  apiGateway: {
    REGION: "ap-northeast-1",
    URL: "https://mdzs3vtmgj.execute-api.ap-northeast-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "ap-northeast-1",
    USER_POOL_ID: "ap-northeast-1_22uFtm2FZ",
    APP_CLIENT_ID: "7e017cd8ut6uvn936i7joooe3i",
    IDENTITY_POOL_ID: "ap-northeast-1:1a7cf86d-2ee6-43d3-894d-b8fd8b61cb1a"
  },
  social: {
    FB: "1757291881042162"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "ap-northeast-1",
    BUCKET: "notes-app-user-data"
  },
  apiGateway: {
    REGION: "ap-northeast-1",
    URL: "https://mdzs3vtmgj.execute-api.ap-northeast-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "ap-northeast-1",
    USER_POOL_ID: "ap-northeast-1_22uFtm2FZ",
    APP_CLIENT_ID: "7e017cd8ut6uvn936i7joooe3i",
    IDENTITY_POOL_ID: "ap-northeast-1:1a7cf86d-2ee6-43d3-894d-b8fd8b61cb1a"
  },
  social: {
    FB: "1757291881042162"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
